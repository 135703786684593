<template>
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
    <template v-slot:error-message>
      <span class="pl-2 text-md">{{ errorMessage }}</span>
    </template>
  </error-toast>
  <Success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
    <template v-slot:success-message>
      <span class="pl-2 text-md">created successfully</span>
    </template>
  </Success-toast>
  <BackArrow :pageTitles="{ heading: 'Edit Budget', content: 'back to budgets' }"/>
  <main class="mx-32">
    <section class="bg-white my-10 p-6 rounded shadow text-sm">
      <h1 class="font-bold text-lg leading-loose">Edit Annual Budget</h1>
      <p>Monthly budgets can be adjusted but the sum must not exceed the annual budget.</p>
      <table class="w-4/5 mt-10  table-fixed border border-borderColor capitalize">
        <thead class="w-full font-semibold text-left">
        <tr class="rounded-md">
          <th class="w-1/5 border border-borderColor py-4 px-5">Month</th>
          <th class="w-1/5 border border-borderColor py-4 px-2">Operating Expenditure (₦)</th>
          <th class="w-1/5 border border-borderColor py-4 px-2">Capital Expenditure (₦)</th>
          <th class="w-1/5 border border-borderColor py-4 px-2">Monthly Budget (₦)</th>
        </tr>
        </thead>
        <tbody v-if="budget" class="text-sm" id="budget">
        <tr data-monthly_budget_row="month_budget_row" v-for="monthly_budget in budget.monthly_budget"
            :key="monthly_budget.id">
          <td class="border border-borderColor py-4 px-5">{{ monthly_budget.month }}</td>
          <td @click="selectFieldToEdit" data-expenses="operating_expenses" class="border border-borderColor py-4 px-5">
            {{ $filters.currencyUSD(monthly_budget.operating_expenses) }}
          </td>
          <td @click="selectFieldToEdit" data-expenses="capital_expenses" class="border border-borderColor py-4 px-5">
            {{ $filters.currencyUSD(monthly_budget.capital_expenses) }}
          </td>
          <td data-monthly_budget="monthly_budget" id="monthly-budget" class="border border-borderColor py-4 px-5">
            {{ $filters.currencyUSD(Number(monthly_budget.operating_expenses) + Number(monthly_budget.capital_expenses)) }}
          </td>
        </tr>
        </tbody>
      </table>
      <section v-if="annualBudget" class="w-4/5 flex flex-row-reverse my-10">
        <div>
          <div class="bg-interactionBg rounded-md px-6 py-3 text-lg shadow-md">
            <span>Retail Annual Budget</span>
            <span class="font-bold ml-2 text-xl">N {{ $filters.currencyUSD(annualBudget) }}</span>
          </div>
          <button @click="updateBudget"
                  class="block ml-auto my-10 bg-primaryColor text-white font-semibold text-center py-3 px-12 rounded-md shadow-md focus:outline-none"
                  :class="{'bg-borderColor cursor-not-allowed': loading}">Update Budget
          </button>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import SuccessToast from '@/components/notificationToasts/SuccessToast.vue'

export default {
  components: { BackArrow, ErrorToast, SuccessToast },
  props: { year: String, department: String },
  data() {
    return {
      budget: null,
      // budgetDepartment: null,
      loading: false,
      annualBudget: 0,
      currentOperatingBudget: null,
      currentCapitalBudget: null,
      newCapitalBudgetValue: null,
      newOperatingBudgetValue: null,
      budgetCapitalDeficit: null,
      budgetOperatingDeficit: null,
      errorMessage: null,
      successMessage: null,

    }
  },
  async mounted() {
    try {
      const { status, data } = await Request.getRequest(`budget/department/${this.department}/year/${this.year}`)

      if (status === 200) {
        // this.budgetDepartment = data.data.department
        this.budget = data.data
        this.computeAnnualBudget()

        // this.$nextTick(() => {
        // this.selectMonthlyBudgetFields()
        // })
      }
    } catch (error) {
      this.errorMessage = error.response.data.message
    }
  },
  methods: {
    async updateBudget() {
      const { rows } = document.querySelector('#budget')

      const monthlyBudget = []
      rows.forEach((row) => {
        const month = row.childNodes[0].textContent
        const operatingExpenses = Number(row.childNodes[1].textContent.replace(/,/g, ''))
        const capitalExpenses = Number(row.childNodes[2].textContent.replace(/,/g, ''))
        monthlyBudget.push({ month, operatingExpenses, capitalExpenses })
      })

      const requestPayload = {
        annual_budget_id: this.budget.id,
        annual_budget: this.annualBudget,
        monthly_budget: monthlyBudget,

      }
      try {

        const { status, data } = await Request.postRequest('budget/update', requestPayload)
        if (status === 201) {
          this.successMessage = data.message
        }
      } catch (error) {
        this.errorMessage = error.response.data.message
        alert(error.response.data.errors.annual_budget[0])
      }
    },
    computeAnnualBudget() {
      this.budget.monthly_budget.forEach((budget) => {
        this.annualBudget += (Number(budget.operating_expenses) + Number(budget.capital_expenses))
      })
    },
    computeMonthlyBudget() {
      // update monthly budget value
      const { rows } = document.querySelector('#budget')

      rows.forEach((row) => {
        const operating = Number(row.childNodes[1].textContent.replace(/,/g, ''))
        const capital = Number(row.childNodes[2].textContent.replace(/,/g, ''))
        row.lastChild.textContent = this.$filters.currencyUSD(operating + capital)
      })
    },
    // selectMonthlyBudgetFields(e) {
    //   const element = document.querySelector(e.target)
    //   this.addContentEditableAttribute(element)
    // },
    selectFieldToEdit(event) {
      this.removeContentEditableAttribute(event.target)
      if (event.target.dataset.expenses === 'capital_expenses') {
        if (!this.budgetCapitalDeficit) {
          this.addContentEditableAttribute(event.target)
        } else {
          event.target.textContent = this.$filters.currencyUSD(Number(event.target.textContent.replace(/,/g, '')) + this.budgetCapitalDeficit)
          this.budgetCapitalDeficit = null
        }
      }
      if (event.target.dataset.expenses === 'operating_expenses') {
        if (!this.budgetOperatingDeficit) {
          this.addContentEditableAttribute(event.target)
        } else {
          event.target.textContent = this.$filters.currencyUSD(Number(event.target.textContent.replace(/,/g, '')) + this.budgetOperatingDeficit)
          this.budgetOperatingDeficit = null
        }
      }
      this.computeMonthlyBudget()
    },
    addContentEditableAttribute(element) {
      element.style.backgroundColor = '#F3F7FF'
      element.setAttribute('contenteditable', true)
      element.focus()
      if (element.dataset.expenses === 'capital_expenses') {
        this.currentCapitalBudget = element.textContent.replace(/,/g, '')
        element.addEventListener('keyup', (event) => {
          this.newCapitalBudgetValue = event.target.textContent.replace(/,/g, '')
        })
      }
      if (element.dataset.expenses === 'operating_expenses') {
        this.currentOperatingBudget = element.textContent.replace(/,/g, '')
        element.addEventListener('keyup', (event) => {
          this.newOperatingBudgetValue = event.target.textContent.replace(/,/g, '')
        })
      }
    },
    removeContentEditableAttribute() {
      const element = document.querySelector('[contenteditable]')
      if (element) {
        element.style.backgroundColor = '#FFFFFF'
        element.removeAttribute('contenteditable')
      }
    }
  },
  watch: {
    newCapitalBudgetValue() {
      if (this.currentCapitalBudget != null) {
        this.budgetCapitalDeficit = Number(this.currentCapitalBudget) - Number(this.newCapitalBudgetValue)
      }
    },
    newOperatingBudgetValue() {
      if (this.currentOperatingBudget != null) {
        this.budgetOperatingDeficit = Number(this.currentOperatingBudget) - Number(this.newOperatingBudgetValue)
      }
    }
  }
}
</script>

<style>

</style>
