<template>
    <BackArrow :pageTitles="{ heading: 'create budget', content: 'back to budget' }" />
    <Success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">created successfully</span>
        </template>
    </Success-toast>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <main
        @click="clickEventListener"
        class="bg-white mx-32 my-8 p-10 rounded-lg shadow-sm pr-56 text-sm"
    >
        <h1 class="text-lg leading-9 font-bold">Create Annual Budget</h1>
        <p>Use the fields below to document the fiscal year budget</p>
        <hr class="my-8" />
        <section class="budget-inputs flex justify-between capitalize">
            <div class="relative">
                <div class="text-xs">year</div>
                <div
                    data-year="year"
                    class="p-3 border border-borderColor rounded mt-2  shadow-sm cursor-pointer"
                >
                    {{ newBudgetData.year ? newBudgetData.year : 'Select Year' }}
                </div>
                <img
                    src="@/assets/images/chevron-down-icon-grey.svg"
                    alt="arrow down icon"
                    data-year="year"
                    class="absolute right-2 bottom-5 cursor-pointer"
                />
                <ul
                    v-if="isShowingYearOptions"
                    class="absolute top-full w-full h-32 overflow-y-scroll overscroll-contain shadow bg-interactionBg"
                >
                    <li
                        v-for="(year, index) in newBudgetOptions.years"
                        :key="index"
                        class="hover:bg-borderColor py-1 px-3"
                        :data-yearOption="year"
                    >
                        {{ year }}
                    </li>
                </ul>
            </div>
            <div class="relative">
                <div class="text-xs">department</div>
                <div
                    data-department="department"
                    class="p-3 border border-borderColor rounded mt-2  shadow-sm cursor-pointer"
                >
                    {{ newBudgetData.department ? newBudgetData.department : 'Select Department' }}
                </div>
                <img
                    data-department="department"
                    src="@/assets/images/chevron-down-icon-grey.svg"
                    alt="arrow down icon"
                    class="absolute right-2 bottom-5 cursor-pointer"
                />
                <ul
                    v-if="isShowingDepartmentOptions"
                    class="absolute top-full w-full shadow bg-interactionBg"
                >
                    <li
                        v-for="(data, index) in newBudgetOptions.departments"
                        :key="index"
                        class="hover:bg-borderColor py-1 px-3"
                        :data-departmentOption="data.department"
                    >
                        {{ data.department }}
                    </li>
                </ul>
            </div>
            <div class="relative">
                <div class="text-xs">operational expenditure</div>
                <div class="flex border border-borderColor rounded mt-2  w-full p-3 shadow-sm">
                    <span class="uppercase font-semibold mr-2">₦</span>
                    <input
                        v-model="newBudgetData.operationalExpenditure"
                        type="number"
                        class="focus:outline-none w-4/5"
                    />
                </div>
            </div>
            <div class="relative">
                <div class="text-xs">capital expenditure</div>
                <div class="flex border border-borderColor rounded mt-2  w-full p-3 shadow-sm">
                    <span class="uppercase font-semibold mr-2">₦</span>
                    <input
                        v-model="newBudgetData.capitalExpenditure"
                        type="number"
                        class="focus:outline-none w-4/5"
                    />
                </div>
            </div>
        </section>
        <button
            @click.prevent="computeBudgetPreview"
            class="bg-primaryColor text-white  rounded-full py-3 px-12 my-12 font-semibold shadow-md focus:outline-none"
            :class="{
                'bg-borderColor cursor-not-allowed':
                    !newBudgetData.capitalExpenditure ||
                    !newBudgetData.operationalExpenditure ||
                    !newBudgetData.year ||
                    !newBudgetData.department,
            }"
        >
            Add to preview
        </button>
        <div v-if="budgetPreviewData.length" class="mt-10 capitalize text-lg font-bold">
            budget year: {{ newBudgetData.year }}
        </div>
        <table
            v-if="budgetPreviewData.length"
            class="w-full table-fixed border border-borderColor capitalize mt-2"
        >
            <thead class="font-semibold text-left">
                <tr class="rounded-md">
                    <th class="w-1/4 border border-borderColor py-4 px-6">month</th>
                    <th class="w-1/4 border border-borderColor py-4 px-6">
                        Operating Expenditure (₦)
                    </th>
                    <th class="w-1/4 border border-borderColor py-4 px-6">
                        Capital Expenditure (₦)
                    </th>
                    <th class="w-1/4 border border-borderColor py-4 px-6">Monthly Budget (₦)</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(budget, index) in budgetPreviewData" :key="index">
                    <td class="border border-borderColor py-4 px-6 capitalize">
                        {{ budget.month }}
                    </td>
                    <td class="border border-borderColor py-4 px-6">
                        {{ $filters.currencyUSD(budget.operationalExpenditure.toFixed(2)) }}
                    </td>
                    <td class="border border-borderColor py-4 px-6">
                        {{ $filters.currencyUSD(budget.capitalExpenditure.toFixed(2)) }}
                    </td>
                    <td class="border border-borderColor py-4 px-6">
                        {{
                            $filters.currencyUSD(
                                (budget.capitalExpenditure + budget.operationalExpenditure).toFixed(
                                    2
                                )
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
        <section v-if="annualBudget" class="flex flex-row-reverse my-10">
            <div>
                <div class="bg-interactionBg rounded-md px-6 py-3 text-lg shadow-md">
                    <span>Retail Annual Budget</span>
                    <span class="font-bold ml-2 text-xl"
                        >N {{ $filters.currencyUSD(annualBudget.toFixed(2)) }}</span
                    >
                </div>
                <button
                    @click="createBudget"
                    class="block ml-auto my-10 bg-primaryColor text-white font-semibold text-center py-3 px-12 rounded-md shadow-md focus:outline-none"
                    :class="{ 'bg-borderColor cursor-not-allowed': loading }"
                >
                    Create Budget
                </button>
            </div>
        </section>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import SuccessToast from '@/components/notificationToasts/SuccessToast.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import { mapState } from 'vuex'

export default {
    components: { BackArrow, SuccessToast, ErrorToast },
    computed: {
        ...mapState(['user']),
    },
    data() {
        return {
            months: [
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december',
            ],
            newBudgetOptions: {
                years: null,
                departments: null,
            },
            newBudgetData: {
                year: null,
                department: null,
                operationalExpenditure: null,
                capitalExpenditure: null,
            },
            annualBudget: 0,
            budgetPreviewData: [],
            isShowingYearOptions: false,
            isShowingDepartmentOptions: false,
            successMessage: null,
            errorMessage: null,
            loading: false,
        }
    },
    methods: {
        clickEventListener(event) {
            const data = event.target.dataset

            if (data.year) {
                this.isShowingYearOptions = true
                this.getListOfYears()
            } else {
                this.isShowingYearOptions = false
            }

            if (data.department) {
                this.isShowingDepartmentOptions = true
                this.getAllDepartments()
            } else {
                this.isShowingDepartmentOptions = false
            }

            if (data.yearoption) {
                this.newBudgetData.year = data.yearoption
                this.isShowingYearOptions = false
            }

            if (data.departmentoption) {
                this.newBudgetData.department = data.departmentoption
                this.isShowingDepartmentOptions = false
            }
        },
        computeBudgetPreview() {
            this.budgetPreviewData = []

            const newBudgetDataValues = Object.values(this.newBudgetData)

            if (newBudgetDataValues.includes(null)) {
                this.errorMessage = 'All fields are required.'
                return
            }

            this.months.forEach(month => {
                const newBudget = {
                    year: this.newBudgetData.year,
                    month,
                    capitalExpenditure: Number(this.newBudgetData.capitalExpenditure) / 12,
                    operationalExpenditure: Number(this.newBudgetData.operationalExpenditure) / 12,
                }

                this.budgetPreviewData.push(newBudget)
            })
            this.computeAnnualBudget()
        },
        computeAnnualBudget() {
            this.annualBudget =
                Number(this.newBudgetData.capitalExpenditure) +
                Number(this.newBudgetData.operationalExpenditure)
        },
        async createBudget() {
            try {
                if (this.loading) {
                    return
                }

                this.loading = true
                const requestPayload = {
                    year: this.newBudgetData.year,
                    operating_expenses: this.newBudgetData.operationalExpenditure,
                    capital_expenses: this.newBudgetData.capitalExpenditure,
                    department: this.newBudgetData.department,
                }
                const { status, data } = await Request.postRequest('budget', requestPayload)
                if (status === 201) {
                    this.loading = false
                    this.successMessage = data.message
                    this.newBudgetData.year = null
                    this.newBudgetData.capitalExpenditure = null
                    this.newBudgetData.operationalExpenditure = null
                    this.newBudgetData.department = null
                    this.budgetPreviewData = []
                    this.annualBudget = 0
                }
            } catch (error) {
                this.loading = false
                this.errorMessage = error.response.data.message
            }
        },
        async getListOfYears() {
            try {
                const { data } = await Request.getRequest('year/list')
                this.newBudgetOptions.years = data.data
            } catch (error) {
                this.errorMessage = error.response.data.message
            }
        },
        async getAllDepartments() {
            try {
                const { data } = await Request.getRequest('departments/all')
                this.newBudgetOptions.departments = data.data
            } catch (error) {
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>

<style scoped>
.budget-inputs > div {
    width: calc(100% / 4);
    margin-right: 4%;
    padding-left: 0;
}

.budget-inputs div:nth-last-child(1) {
    margin-right: 0;
}
</style>
