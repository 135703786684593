<template>
  <LoadingSpinner v-if="loading" />
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <main class="mx-36 my-10">
        <h1 class="text-4xl">Budget</h1>
        <section class="budget-cards-wrapper my-8">
            <router-link :to="{name: 'newBudget'}" class="flex flex-col items-center justify-items-center border border-borderColor rounded p-10 text-sm">
                <img src="@/assets/images/plus-icon.svg" alt="add icon">
                <div class="my-2">Create Annual Budget</div>
            </router-link>
            <router-link :to="{name: 'budgetView', params: { year: budget.year }}" v-for="budget in budgets" :key="budget.year" class="budget-cards px-5 py-12 text-center rounded text-white">
                <div class="text-4xl">{{ budget.year }}</div>
<!--                <div class="text-sm mt-4">Created on {{ moment(budget.created_at).format('D MMM, YYYY') }}</div>-->
            </router-link>
        </section>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import moment from 'moment'

export default {
    name: 'Budget',
    async mounted() {
        try {
            this.setCardBackground()
            const { status, data } = await Request.getRequest('budget')

            if (status === 200) {
              this.budgets = data.data
              this.loading = false

            }
        } catch (error) {
            this.errorMessage = error.response.data.message
        }
    },
    updated() {
        this.$nextTick(() => {
            this.setCardBackground()
        })
    },
    components: {
        ErrorToast
    },
    data() {
        return {
            budgets: null,
            moment,
            errorMessage: null,
            loading: true
        }
    },
    methods: {
        setCardBackground() {
            const elements = document.querySelectorAll('.budget-cards')

            elements.forEach((element, index) => {
                if (index === 0) {
                    element.classList.add('bg-cardBackgroundColor-pink')
                } else if (index === 1) {
                    element.classList.add('bg-cardBackgroundColor-purple')
                } else {
                    element.classList.add('bg-cardBackgroundColor-green')
                }
            })
        }
    }
}
</script>

<style scoped>
.budget-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}
</style>
